import request from '@/utils/request'

const api = {
  contractListPage: '/contract/contractListPage',
  contractAdd: '/contract/add',
  contractUpdate: '/contract/update',
  advertiserList: 'advertiser/advertiserList', // 广告主
  agentList: 'agent/agentList', // 代理
  businessUserList: 'contract/businessUserList',

  invoiceListPage: 'invoice/invoiceListPage', // 发票列表
  invoiceAdd: 'invoice/add', // 发票新增
  invoiceUpdate: 'invoice/update', // 发票修改
  addTaxInfo: 'invoice/addTaxInfo', // 发票抬头新增
  taxInfoList: 'invoice/taxInfoList', // 发票抬头查询
  incomeListPage: 'income/incomeListPage', // 收入统计查询
  incomeAdd: 'income/add', // 收入统计添加
  incomeUpdate: 'income/update', // 收入统计修改
  capitalListPage: 'capital/capitalListPage', // 资金管理列表
  doIncome: 'income/doIncome', // 操作到账
  agree: 'income/agree', // 审核同意
  refuse: 'income/refuse', // 审核拒绝
  // 获取媒体账号列表
  getMediaUserList: 'settlementManager/getMediaUserList',
  // 获取支出管理报表数据
  getSettlementPage: '/settlementManager/getSettlementPage',
  // 修改支出管理报表数据
  settlementUpdate: '/settlementManager/updateData',
  // mediatom报表
  adxSettleReport: 'adxSettle/report',
  // mediatom报表详情
  adxSettleReportDetails: 'adxSettle/reportDetails',
  // mediatom报表修改状态
  adxSettleUpdateSts: 'adxSettle/updateSts'
}

export default api

export function contractListPage (data) {
  return request({
    url: api.contractListPage,
    method: 'post',
    data
  })
}
export function contractAdd (data) {
  return request({
    url: api.contractAdd,
    method: 'post',
    data
  })
}
export function contractUpdate (data) {
  return request({
    url: api.contractUpdate,
    method: 'post',
    data
  })
}
export function advertiserList (data) {
  return request({
    url: api.advertiserList,
    method: 'post',
    data
  })
}
export function agentList (data) {
  return request({
    url: api.agentList,
    method: 'post',
    data
  })
}
export function businessUserList (data) {
  return request({
    url: api.businessUserList,
    method: 'get'
  })
}
export function invoiceListPage (data) {
  return request({
    url: api.invoiceListPage,
    method: 'post',
    data
  })
}
export function invoiceAdd (data) {
  return request({
    url: api.invoiceAdd,
    method: 'post',
    data
  })
}
export function invoiceUpdate (data) {
  return request({
    url: api.invoiceUpdate,
    method: 'post',
    data
  })
}
export function addTaxInfo (data) {
  return request({
    url: api.addTaxInfo,
    method: 'post',
    data
  })
}
export function taxInfoList (data) {
  return request({
    url: api.taxInfoList,
    method: 'post',
    data
  })
}
export function incomeListPage (data) {
  return request({
    url: api.incomeListPage,
    method: 'post',
    data
  })
}
export function incomeAdd (data) {
  return request({
    url: api.incomeAdd,
    method: 'post',
    data
  })
}
export function incomeUpdate (data) {
  return request({
    url: api.incomeUpdate,
    method: 'post',
    data
  })
}
export function capitalListPage (data) {
  return request({
    url: api.capitalListPage,
    method: 'post',
    data
  })
}
export function doIncome (data) {
  return request({
    url: api.doIncome,
    method: 'post',
    data
  })
}
export function agree (data) {
  return request({
    url: api.agree,
    method: 'post',
    data
  })
}
export function refuse (data) {
  return request({
    url: api.refuse,
    method: 'post',
    data
  })
}

export function getMediaUserList () {
  return request({
    url: api.getMediaUserList,
    method: 'post'
  })
}

export function getSettlementPage (data) {
  return request({
    url: api.getSettlementPage,
    method: 'post',
    data
  })
}
export function adxSettleReport (data) {
  return request({
    url: api.adxSettleReport,
    method: 'post',
    data
  })
}
export function adxSettleReportDetails (data) {
  return request({
    url: api.adxSettleReportDetails,
    method: 'post',
    data
  })
}
export function adxSettleUpdateSts (data) {
  return request({
    url: api.adxSettleUpdateSts,
    method: 'post',
    data
  })
}
export function settlementUpdate (data) {
  return request({
    url: api.settlementUpdate,
    method: 'post',
    data
  })
}
