<template>
  <a-modal v-model="addPlaceView" :title="this.names" :closable="false">
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <a-row>
        <a-col :span="16">
          <a-form-model-item has-feedback label="合同编号">
            {{ query.contractNo }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="合同状态">
            {{ query.contractSts }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="合同期限">
            {{ query.contractStartTime + '————' + query.contractEndTime }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="客户经理">
            {{ query.username }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="合同类型">
            {{ query.contractType }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="甲方公司">
            {{ query.companyName }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="乙方公司">
            {{ query.contractSecondParty }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入模式">
            {{ query.incomeType }}
          </a-form-model-item>
          <a-form-model-item has-feedback label="收款方式">
            {{ query.collectionType }}
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item has-feedback label="图标">
            <!-- <img v-if="imagesUrl" :src="imagesUrl" alt="图标" style="width: 120px" /> -->
            <div v-for="item in this.imagesPush" :key="item">
              <img v-if="item" :src="resourceUrl + item" alt="图标" style="width: 120px" />
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <!-- <a-button type="danger" @click="handleCancel" > 拒绝 </a-button>
      <a-button type="primary" style="margin-left: 10px" @click="handleSubmit" v-if="this.isAdd === 'a'">
        通过
      </a-button> -->
      <a-button type="danger" @click="handleDown"> 关闭 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      appList: [],
      positionList: [],
      templateList: [],
      names: '',
      companyTypes: ''
    }
  },
  props: {
    isAdd: {
      default: '',
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    appId: {
      default: '',
      type: String
    },
    placeView: {
      type: [Boolean, Object],
      default: () => ({})
    }
  },
  watch: {
    placeView: {
      handler (val) {
        this.names = '合同查看'
        this.query = JSON.parse(JSON.stringify(val))
        this.imagesPush = val.attachmentUrls.split(',')

        if (val.contractSts === 0) {
          this.query.contractSts = '未归档'
        } else if (val.contractSts === 1) {
          this.query.contractSts = '归档'
        }
        if (val.contractType === 1) {
          this.query.contractType = '媒体和代理'
        } else if (val.contractType === 2) {
          this.query.contractType = '媒体和广告主'
        }
        if (val.incomeType === 1) {
          this.query.incomeType = '直客收入'
        } else if (val.incomeType === 2) {
          this.query.incomeType = '平台收入'
        }
        if (val.collectionType === 1) {
          this.query.collectionType = '预收'
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {},
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceView: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleDown () {
      this.addPlaceView = false
    }
  }
}
</script>

<style></style>
